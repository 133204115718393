import React from "react";
import logo from './marianareid-marca-CMYK_marianareid-principal-fundo-transparente.svg';
import headshot from './images/IMG_3018.jpeg';
import igLogo from './instagram.svg';
import waLogo from './whatsapp.svg';
import './App.css';
import Modal from "./components/modal.js";


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
  }
  changeBackgroundHover(e) {
    e.target.style.background = '#a80538';
    e.target.style.color = 'white';
    e.target.style.cursor = 'pointer';
  }
  changeBackgroundNoHover(e) {
    e.target.style.background = '';
    e.target.style.color = '#683b11';
    e.target.style.cursor = '';
  }

  modalOpen() {
    this.setState({ modal: true });
  }

  modalClose() {
    this.setState({
      modal: false
    });
  }
  render() {
    return (
      <div className="App">

        <header className="App-header">
          <img src={headshot} className="App-headshot" alt="Picture of Mariana" />

        </header>
        <div className='App-content'>
        <button className="linkBlock"
            onMouseEnter={this.changeBackgroundHover}
            onMouseLeave={this.changeBackgroundNoHover}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://wa.me/353899840650';
            }}>Consulta atendimento on-line</button>
            
          <button className="linkBlock"
            onMouseEnter={this.changeBackgroundHover}
            onMouseLeave={this.changeBackgroundNoHover}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://chat.whatsapp.com/Ildo6AjNIl0GJvEb1OhL69';
            }}>Aulões Semanais Gratuitos</button>

          

          {/*<button className="linkBlock" 
          onMouseEnter={this.changeBackgroundHover} 
          onMouseLeave={this.changeBackgroundNoHover} 
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://chat.whatsapp.com/Ke0UwvHFEbmCqE3LxOmmMK';
          }}>Curso Gratuito Descomplicando a IA</button>*/}
          {/* <button className="linkBlock" onMouseEnter={this.changeBackgroundHover} onMouseLeave={this.changeBackgroundNoHover} onClick={e => this.modalOpen(e)}>Curso gratuito</button>
        <button className="linkBlock" onMouseEnter={this.changeBackgroundHover} onMouseLeave={this.changeBackgroundNoHover}>Aulões online</button> */}
          {/* <button 
          className="linkBlock" 
          onMouseEnter={this.changeBackgroundHover} 
          onMouseLeave={this.changeBackgroundNoHover} 
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://www.instagram.com/marianareid.nutri/';
          }}>
            Instagram Profile
        </button> */}
          {/* <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
          <div>Sign up Here!!!</div>
        </Modal>  */}
        </div>
        <div class="footer"><a href="https://www.instagram.com/marianareid.nutri/">
          <img src={igLogo} className="ig-logo" alt="Instagram Logo" />
        </a>
          <a href="https://wa.me/353899840650">
            <img src={waLogo} className="ig-logo" alt="Whatsapp Logo" />
          </a>
        </div>
        <div>
          <img src={logo} className="footer-logo" alt="logo" />
        </div>

      </div>
    );
  }
}

export default App;
